import {React, styled} from 'common'
import {
    useHistory,
    useDispatch,
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'services/hooks'
import ControlButtons from 'components/Details/ControlButtons'

import {
    createFormData,
    formatDataToDragAndDrop,
    formatDataToDragAndDropFullObject,
    getIsActiveState,
    getIsEmptyMandatoryField,
    getSuccessSaveMessageData,
    showAlertMessage,
    getEntityDeletionAlertProps,
    calculateWorkout,
} from 'services/utils'
import WorkoutsDragAndDrop from 'components/general/Control/DragAndDrop/WorkoutsDragAndDrop'
import ProgramGeneralContent from 'components/Program/Details/ProgramGeneralContent'
import {
    AEROBIC_FIELDS,
    programMandatoryFields,
    PROGRAM_TYPE_CORPORATE,
    STEPS_FIELDS,
    STRENGTH_FIELDS,
} from 'constant/program'
import LabelsDragAndDrop from 'components/general/Control/DragAndDrop/LabelsDragAndDrop'
import {ActivationInput} from 'components/Details'
import Spinner from 'components/general/Display/Spinner'
import {
    useGetProgramsQuery,
    useGetPaginationWorkoutQuery,
    useDeleteProgramMutation,
} from 'store/store'
import {SweetAlert} from 'components/general/Display'
import {TARGET_REPETITIONS, WorkoutContentType} from 'constant/workout'

const getIsCorporateFieldsFull = (updatedProgram) => {
    let isFilled =
        AEROBIC_FIELDS?.every(
            ({name: fieldName}) => updatedProgram[fieldName]
        ) ||
        STEPS_FIELDS?.every(({name: fieldName}) => updatedProgram[fieldName])

    if (updatedProgram?.workouts?.length) {
        isFilled = STRENGTH_FIELDS?.every(
            ({name: fieldName}) => updatedProgram[fieldName]
        )
    }

    return isFilled
}

const isEmptyMandatoryField = (program) =>
    getIsEmptyMandatoryField(programMandatoryFields, program)

const ProgramDetails = ({
    program,
    isNewProgram,
    showDraftError,
    setShowDraftError,
    onError,
    saveProgram,
    saveOptions,
    language,
    setLanguage,
    showError,
    showSpinner,
    setShowError,
    showNameError,
    setShowNameError,
    updatedProgram,
    setUpdatedProgram,
    showSelectedWorkoutsError,
    setShowSelectedWorkoutsError,
    showCorporateError,
    setShowCorporateError,
    showSelectedTagsError,
    setShowSelectedTagsError,
    doneFormattingWorkouts,
    setDoneFormattingWorkouts,
}) => {
    const {isError, isSuccess} = saveOptions
    const history = useHistory()
    const dispatch = useDispatch()

    const [queryPaginateWo, setQueryPaginateWo] = useState({
        target: TARGET_REPETITIONS,
        active: true,
        newVersion: true,
        keywords: '',
        content_type: WorkoutContentType.Strength,
    })
    const [queryPaginateWoAerobicContent, setQueryPaginateWoAerobicContent] =
        useState({
            active: true,
            newVersion: true,
            keywords: '',
            content_type: WorkoutContentType.Aerobic,
        })

    const {data: programWo} = useGetPaginationWorkoutQuery({
        ids: program?.workouts,
    })
    const {data: programWoAerobicContent} = useGetPaginationWorkoutQuery({
        ids: program?.aerobic_content,
    })
    const {data: paginateWo} = useGetPaginationWorkoutQuery(queryPaginateWo)
    const {data: paginateWoAerobicContent} = useGetPaginationWorkoutQuery(
        queryPaginateWoAerobicContent
    )
    const {data: programs} = useGetProgramsQuery()
    const [selectedWorkouts, setSelectedWorkouts] = useState([])
    const [selectedAerobicContent, setSelectedAerobicContent] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [showDeletionPopUp, setShowDeletionPopUp] = useState(false)
    const [workoutsGaps, setWorkoutsGaps] = useState([])
    const [prevSelectedWorkout, setPrevSelectedWorkout] = useState(null)
    const [prevRepsTarget, setPrevRepsTarget] = useState(0)

    //filter exercise variables:
    const [imageFile, setImageFile] = useState({file: null, previewUrl: null})
    const [longImage, setLongImage] = useState({file: null, previewUrl: null})
    const [smallImage, setSmallImage] = useState({file: null, previewUrl: null})

    const generalContentRef = useRef()
    const alertRef = React.createRef()

    const {name, active, program_type} = updatedProgram || {}
    const isCorporate = program_type === PROGRAM_TYPE_CORPORATE

    const updateProgramField = useCallback(
        (field, value) => {
            setUpdatedProgram((prevState) => ({...prevState, [field]: value}))
        },
        [setUpdatedProgram]
    )

    const [deleteProgram, {isSuccess: isSuccessDeleting}] =
        useDeleteProgramMutation()

    const redirectToList = useCallback(
        (redirect = false) => redirect && history.goBack(),
        [history]
    )
    useEffect(() => {
        const shouldShowSuccess = isSuccess || isSuccessDeleting
        if (shouldShowSuccess) {
            const {message, type, redirect} = getSuccessSaveMessageData(name)

            showAlertMessage(message, type, dispatch)
            redirect && redirectToList(true)
        }
    }, [dispatch, isError, isSuccess, name, redirectToList, isSuccessDeleting])

    useEffect(() => {
        if (!doneFormattingWorkouts) {
            if (!program?.workouts?.length) {
                setDoneFormattingWorkouts(true)
            } else {
                selectedWorkouts?.length && setDoneFormattingWorkouts(true)
            }
        }
    }, [
        doneFormattingWorkouts,
        program,
        selectedWorkouts,
        setDoneFormattingWorkouts,
    ])

    // eslint-disable-next-line complexity
    useEffect(() => {
        if (program) {
            // set gaps here
            setWorkoutsGaps(program?.workouts_gaps || [])
            const formatedData = formatDataToDragAndDropFullObject(
                programWo?.data
            )
            setSelectedWorkouts(formatedData)
            if (!prevSelectedWorkout || !prevSelectedWorkout?.length) {
                setPrevSelectedWorkout(formatedData)
            }
            if (!prevRepsTarget) {
                setPrevRepsTarget(program?.strength_reps_daily_target)
            }
            if (program?.aerobic_content?.length) {
                setSelectedAerobicContent(
                    formatDataToDragAndDropFullObject(
                        programWoAerobicContent?.data
                    )
                )
            }
            setSelectedTags(formatDataToDragAndDrop(program.tags))
            setImageFile({file: null, previewUrl: program.image})
            setLongImage({file: null, previewUrl: program.tall_image})
            setSmallImage({file: null, previewUrl: program.small_image})
        }
    }, [
        prevRepsTarget,
        prevSelectedWorkout,
        program,
        programWo,
        programWoAerobicContent,
    ])

    const checkIsNameUniqe = useCallback(() => {
        const existingName = programs?.find(
            (pr) =>
                pr.name.toLowerCase().trim() === name.toLowerCase().trim() &&
                program?.id !== pr.id
        )
        return !existingName
    }, [name, program, programs])

    const clearAlerts = useCallback(() => {
        const alertsFunctions = [
            setShowSelectedTagsError,
            setShowSelectedWorkoutsError,
            setShowError,
            setShowNameError,
            setShowDraftError,
            setShowCorporateError,
        ]
        alertsFunctions.forEach((func) => func(false))
    }, [
        setShowSelectedTagsError,
        setShowSelectedWorkoutsError,
        setShowError,
        setShowNameError,
        setShowDraftError,
        setShowCorporateError,
    ])

    const showCorporateErros = () => {
        onError('showCorporateError')
        setShowSelectedWorkoutsError(true)
        setShowCorporateError(true)
        return true
    }

    const showRequiredFieldsError = () => {
        onError('showError')
        setShowError(true)
        generalContentRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'start',
        })
        return true
    }

    const handleCorporateMandatoryFields = () => {
        if (!name) {
            return showRequiredFieldsError()
        }

        if (
            getIsCorporateFieldsFull({
                ...updatedProgram,
                workouts: selectedWorkouts,
            })
        ) {
            return false
        }

        return showCorporateErros()
    }

    const prevWorkoutHasRemoved = () => {
        let notAbleToSave = false
        prevSelectedWorkout?.forEach((val) => {
            const found = selectedWorkouts?.find((v) => v.id === val.id)
            if (!found) {
                notAbleToSave = true
            }
        })

        return notAbleToSave
    }

    const prevRepsHasChange = () => {
        return prevRepsTarget !== updatedProgram.strength_reps_daily_target
    }

    // eslint-disable-next-line complexity
    const isErrorFound = () => {
        clearAlerts()

        if (
            updatedProgram.has_user_in_workout &&
            prevWorkoutHasRemoved() &&
            !isNewProgram
        ) {
            onError('showErrorCantSaveSelectedWorkouts')
            return true
        }

        if (
            updatedProgram.has_user_in_workout &&
            prevRepsHasChange() &&
            !isNewProgram
        ) {
            onError('showErrorCantSaveRepsTargetHasChange')
            return true
        }

        if (isCorporate) {
            return handleCorporateMandatoryFields()
        }

        const isRequiredFieldsFull = isEmptyMandatoryField({
            ...updatedProgram,
            image: imageFile,
            tall_image: longImage,
            small_image: smallImage,
        })
        if (!selectedWorkouts.length) {
            onError('showSelectedWorkoutsError')
            setShowSelectedWorkoutsError(true)
            return true
        }
        if (!selectedTags.length) {
            onError('showSelectedTagsError')
            setShowSelectedTagsError(true)
            return true
        }
        if (!isRequiredFieldsFull) {
            return showRequiredFieldsError()
        }

        const isProgramNameUniqe = checkIsNameUniqe()
        if (!isProgramNameUniqe) {
            onError('showNameError')
            setShowNameError(true)
            return true
        }
    }

    const addProgramImages = (newProgram) => {
        if (imageFile.file) {
            newProgram.img = imageFile.file
        }
        if (smallImage.file) {
            newProgram.small_img = smallImage.file
        }
        if (longImage.file) {
            newProgram.tall_img = longImage.file
        }
        delete newProgram.image
    }

    const prepareWorkouts = () => {
        let workouts = []
        if (selectedWorkouts.length) {
            workouts = selectedWorkouts.map((workout) => workout.id)
        }
        return workouts
    }

    const prepareAerobicContent = () => {
        let aerobicContent = []
        if (selectedAerobicContent?.length) {
            aerobicContent = selectedAerobicContent.map((wo) => wo.id)
        }

        return aerobicContent
    }

    const prepareTags = () => selectedTags.map((tag) => ({_id: tag.id}))

    const prepareWorkoutsGaps = () => {
        let gaps = []
        if (workoutsGaps.length) {
            gaps = workoutsGaps.map((gap) => Number(gap))
        } else {
            gaps = selectedWorkouts.map(() => 0)
        }

        return gaps
    }

    const checkArrayField = (fieldValue) => {
        if (!fieldValue) {
            return []
        } else {
            return Array.isArray(fieldValue) ? fieldValue : [fieldValue]
        }
    }

    const prepareNewProgram = () => {
        const {instructions} = updatedProgram || {}
        return {
            ...updatedProgram,
            name: name.trim(),
            workouts: prepareWorkouts(),
            workouts_gaps: prepareWorkoutsGaps(),
            aerobic_content: prepareAerobicContent(),
            tags: prepareTags(),
            instructions: checkArrayField(instructions),
        }
    }

    const handleDraftError = () => {
        onError('showError')
        setShowDraftError(true)
        return false
    }

    const handleNameCheckBeforeSave = () => {
        const isNmaeUniqe = checkIsNameUniqe()
        if (!isNmaeUniqe) {
            onError('showNameError')
            setShowNameError(true)
            return false
        }
        return true
    }

    const checkIsAllowdToSave = (isActive) => {
        if (isActive && isErrorFound()) {
            return false
        }
        if (!isActive) {
            if (!updatedProgram.name) {
                return handleDraftError()
            } else {
                return handleNameCheckBeforeSave()
            }
        }

        return true
    }

    /**
     * Description: additional prevent on save if isCorporate is checked
     * @param {boolean} toggleActive
     */
    // eslint-disable-next-line complexity
    const onSave = async (ev, toggleActive = false) => {
        ev && ev.preventDefault()

        const isActiveState = getIsActiveState(toggleActive, updatedProgram)

        if (!checkIsAllowdToSave(isActiveState)) {
            return
        }
        const newProgram = prepareNewProgram()
        addProgramImages(newProgram)

        if (toggleActive) {
            newProgram.active = !newProgram.active
        }
        doSaveProgram(newProgram)
    }

    const doSaveProgram = async (newProgram) => {
        const programFormData = createFormData(newProgram)
        try {
            await saveProgram({
                program: programFormData,
                id: program?.id,
            }).unwrap()
        } catch (err) {
            const {message} = err.data
            showAlertMessage(message, 'alert-danger', dispatch)
            redirectToList(false)
        }
    }

    const onActivate = () => onSave(null, true)

    useEffect(() => {
        name?.length && setShowNameError(false)
    }, [name, setShowNameError])

    useEffect(() => {
        showDeletionPopUp && alertRef?.current()
    }, [showDeletionPopUp, alertRef])

    if (showSpinner) {
        return (
            <SpinnerContainer className="d-flex w-100 h-100 justify-content-center align-items-center flex-grow-1">
                <Spinner label={'PREPARING PROGRAM DETAILS . . .'} />
            </SpinnerContainer>
        )
    }

    const deletionAction = (id) => {
        deleteProgram(id)
    }
    return (
        <div header={'Program Details'}>
            <GeneralContentContainer ref={generalContentRef}>
                <ProgramGeneralContent
                    isNewProgram={isNewProgram}
                    setImageFile={setImageFile}
                    setLongImage={setLongImage}
                    setSmallImage={setSmallImage}
                    imageFile={imageFile}
                    longImage={longImage}
                    smallImage={smallImage}
                    language={language}
                    setLanguage={setLanguage}
                    showError={showError}
                    showDraftError={showDraftError}
                    showNameError={showNameError}
                    showCorporateError={showCorporateError}
                    updatedProgram={updatedProgram}
                    updateProgramField={updateProgramField}
                />
            </GeneralContentContainer>

            <WorkoutsDragAndDrop
                showSelectedWorkoutsError={showSelectedWorkoutsError}
                setSelectedWorkouts={(arr) => {
                    let repetitions = arr?.map((wo) => {
                        return calculateWorkout(wo.workout_activities)
                            .totalTarget
                    })
                    const repsTarget = Math.min(...repetitions)
                    updateProgramField('strength_reps_daily_target', repsTarget)
                    updateProgramField(
                        'strength_days_target',
                        repetitions.length
                    )

                    setWorkoutsGaps(arr?.map(() => 1))

                    setSelectedWorkouts(arr)
                }}
                selectedWorkouts={selectedWorkouts}
                workouts={paginateWo?.data}
                workoutsGaps={workoutsGaps}
                setWorkoutsGaps={setWorkoutsGaps}
                isProgram
                programWorkoutFlowType={updatedProgram.flow_type}
                setQueryPaginateWo={setQueryPaginateWo}
            />

            <WorkoutsDragAndDrop
                setSelectedWorkouts={(arr) => {
                    setSelectedAerobicContent((old) => {
                        const uniqueAerobicContent = [
                            ...new Set([...old, ...arr].map((item) => item.id)),
                        ].map((id) =>
                            [...old, ...arr].find((item) => item.id === id)
                        )

                        return uniqueAerobicContent
                    })
                }}
                selectedWorkouts={selectedAerobicContent}
                workouts={paginateWoAerobicContent?.data}
                setQueryPaginateWo={setQueryPaginateWoAerobicContent}
                dragTitle="Available Aerobic Content"
                dropTitle="Selected Aerobic Content"
            />
            <LabelsDragAndDrop
                hide={isCorporate}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                showSelectedTagsError={showSelectedTagsError}
            />

            {isNewProgram && (
                <ActivationInput
                    type={'program'}
                    active={active || false}
                    setactive={(value) => updateProgramField('active', value)}
                />
            )}
            <ControlButtons
                hideActivationButton={isNewProgram}
                hideControls={!isNewProgram && program?.version < 2}
                isActive={Boolean(program?.active)}
                onSave={onSave}
                onActivate={onActivate}
                onDelete={() => setShowDeletionPopUp(true)}
                topic={'Program'}
            />
            <SweetAlert
                ref={alertRef}
                hideAlertExtraFunction={() => setShowDeletionPopUp(false)}
                id={program?.id}
                {...getEntityDeletionAlertProps(
                    program,
                    'Program',
                    deletionAction
                )}
            />
        </div>
    )
}

export default ProgramDetails
const SpinnerContainer = styled.div``
const GeneralContentContainer = styled.div``
